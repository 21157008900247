import { Button, ButtonGroup, FormControl, FormLabel, HStack, Input, Stack } from "@chakra-ui/react";
import React from "react";
import { useForm, useWatch, Controller, FormProvider, useFormContext } from "react-hook-form";
import Debug from "../../../utils/Debug";
import { ActionField } from "./Fields/Action";
import { SubjectField } from "./Fields/Subject";

export default function NewAdForm() {
    const methods = useForm();
    const onSubmit = data => console.log(data);

    const steps = ['action', 'subject'];
    const [maxStep, setMaxStep] = React.useState(0);



    // console.log(watch("action")); // watch input value by passing the name of it

    // React.useEffect(
    //     () => { console.log('use effect', methods.getValues()); },
    //     [methods.getValues()]
    // )

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            console.log(value, name, type);
            // console.log('nextStep', steps.indexOf(name))
            setMaxStep(steps.indexOf(name) + 1);
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return (
        /* "handleSubmit" will validate your inputs before invoking "onSubmit" */

        <FormProvider {...methods} >
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Stack
                    spacing={3}
                >
                    <ActionField />

                    {maxStep >= 1 ? <SubjectField /> : null}
                    <input type="submit" />
                </Stack>
            </form>
            <Debug data={methods.getValues()} />
        </FormProvider>


    );
}