import * as React from "react";
import { Button, ButtonGroup, FormControl, FormLabel } from "@chakra-ui/react"

import { Controller, useFormContext } from "react-hook-form";

export const ActionField = ({ }) => {

    const { register, handleSubmit, control, watch, formState: { errors } } = useFormContext();

    return (

        <FormControl>
            <FormLabel>Vous êtes ici pour... ?</FormLabel>

            <ButtonGroup>

                <>
                    <Controller
                        name='action'
                        control={control}
                        render={({ field }) => {
                            return (
                                <>

                                    <Button
                                        as='label'
                                        cursor='pointer'
                                        htmlFor="field-sell"
                                        colorScheme={field.value === 'sell' ? 'blue' : null}
                                    >
                                        Vendre
                                    </Button>
                                    <input
                                        {...register("action")}
                                        type="radio"
                                        name="action"
                                        value="sell"
                                        id="field-sell"
                                        style={{ opacity: .2, clipPath: 'inset(100%)', textIndent: '-9000px', position: 'absolute' }}
                                    />
                                </>

                            )
                        }}
                    />
                    <Controller
                        name='action'
                        control={control}
                        render={({ field }) => {
                            return (
                                <>
                                    <input
                                        {...register("action")}
                                        type="radio"
                                        name="action"
                                        value="search"
                                        id="field-search"
                                        // style={{ opacity: .2 }}
                                        style={{ opacity: .2, clipPath: 'inset(100%)', textIndent: '-9000px', position: 'absolute' }}
                                    />
                                    <Button
                                        as='label'
                                        cursor='pointer'
                                        htmlFor="field-search"
                                        colorScheme={field.value === 'search' ? 'blue' : null}
                                    >
                                        Chercher
                                    </Button>
                                </>

                            )
                        }}
                    />

                </>

            </ButtonGroup>


        </FormControl>
    )
}