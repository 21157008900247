import React, { useState, useEffect } from "react"
import { Link as GatsbyLink } from 'gatsby'
import LayoutAccount from "../components/layouts/layoutAccount"
import { Box, Button, Flex, Heading, Image, Text } from "@chakra-ui/react"
import { FormattedMessage } from 'react-intl';
import Seo from '../components/Seo/';
import AdForm from '../components/Form/AdForm'
import logo from '../images/logo.svg'
import AuthService from '../../services/auth.service'
import GetAuthLinkForm from '../components/GetAuthLinkForm'
import { BiLeftArrowAlt } from "react-icons/bi";
import NewAdForm from "../components/Form/AdNewForm";

const AdFormNew = (props) => {
  const title = {
    "fr": "Publier une annonce gratuitement"
    ,
    "en": "Publish an ad for free"
  }
  const description = {
    "fr": "Voici le formulaire de publication d'annonce de Wanaboat. 3 min seulement pour déposer une annonce gratuite."
    ,
    "en": "Here is the publication form of iWannaboat. 3 min only to place a free ad."
  }
  const [user, setUser] = useState(false)
  useEffect(() => {
    setUser(AuthService.getCurrentUser())
  }, []);

  return (
    <LayoutAccount lang={process.env.GATSBY_LANG}>
      <Seo
        title={
          title[process.env.GATSBY_LANG]
        }
        description={
          description[process.env.GATSBY_LANG]
        }
        slug={''}
      />
      <Box>
        <Flex
          p='2rem'
          borderBottom={{ base: 'none', lg: 'solid 1px' }}
          borderBottomColor={{ lg: 'gray.100' }}
          justifyContent='space-between'
        >
          <Box>
            <Button
              as={GatsbyLink}
              to="/"
              leftIcon={<BiLeftArrowAlt />}
            >
              <FormattedMessage id="main.back.to_webiste" />
            </Button>
          </Box>
          <Box>
            <Image src={logo} w="50px" h="50px" />
          </Box>
        </Flex>
      </Box>
      <Box maxW='900px' mx='auto' px="2rem" py="3rem">

        <NewAdForm />
        {/* {!user ?
          <Box>
            <Heading mb={5} color='gray.800'>
              <FormattedMessage id="before.you.start" />
            </Heading>
            <GetAuthLinkForm redirect={process.env.GATSBY_LANG === 'fr' ? "/capitainerie/publier/" : "/harbour-office/publish/"} />
          </Box>
          :
          <AdForm user={user.userId} />
        } */}
      </Box>
    </LayoutAccount>
  )
}

export default AdFormNew